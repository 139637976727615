<!-- eslint-disable max-len -->
<template>
    <div class="incubator-confirm">
        <v-form>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.organizeName"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[0]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.Confirm.hint[0]')"
                            persistent-hint
                            class="border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.team.type"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="非正式立案組織（個人團隊）"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[0]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="非營利組織"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[1]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="營利組織"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[2]') }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[2]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-select
                            v-model="form.team.country"
                            :items="countries"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[1]')"
                            class="border-orange-input form-input"
                            readonly
                        />
                        <v-text-field
                            v-if="form.team.country !== '臺灣'"
                            v-model="form.team.city"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[2]')"
                            placeholder=""
                            class="border-orange-input form-input"
                            readonly
                        />
                        <v-select
                            v-else
                            v-model="form.team.city"
                            :items="cities"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[2]')"
                            class="border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[3]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.taxId"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[3]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.Confirm.hint[1]')"
                            persistent-hint
                            class="border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[4]')"
                            placeholder=""
                            class="border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[5]') }}
                    </div>
                    <div class="tips">
                        {{ $t('IncubatorApply.TeamData.tips[0]') }}<br>{{
                            $t('IncubatorApply.TeamData.tips[1]')
                        }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="member-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    placeholder=""
                                    class="border-orange-input form-input"
                                    readonly
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('IncubatorApply.Confirm.label[7]')"
                                    placeholder=""
                                    class="border-orange-input form-input"
                                    readonly
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    :label="$t('IncubatorApply.Confirm.label[8]')"
                                    placeholder=""
                                    class="border-orange-input form-input"
                                    readonly
                                />
                                <v-text-field
                                    v-model="item.birthday"
                                    outlined
                                    :label="$t('IncubatorApply.Confirm.label[9]')"
                                    placeholder=""
                                    class="border-orange-input form-input"
                                    readonly
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('IncubatorApply.Confirm.label[10]')"
                                    placeholder=""
                                    class="border-orange-input"
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.TeamData.title[6]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][0]')"
                        value="FUN大視野官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][1]')"
                        value="FUN大視野FACEBOOK"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][2]')"
                        value="FUN大視野INSTAGRAM"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][3]')"
                        value="FUN大視野電子報"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][4]')"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][5]')"
                        value="縣市政府宣傳平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][6]')"
                        value="社會創新平台官網"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][7]')"
                        value="獎金獵人平台"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex; flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][8]')"
                            value="歷屆團隊推薦，團隊名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers1"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                    <div style="display: flex; flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][9]')"
                            value="親朋好友推薦，推薦人:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers2"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][10]')"
                        value="曾經提案過"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][11]')"
                        value="原本就知道"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][12]')"
                        value="IMPACT HUB TAIPEI宣傳平台(官網、電子報、口頭邀約等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('IncubatorApply.TeamData.checkbox[0][13]')"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#898989"
                        class="checkbox"
                        readonly
                    />
                    <div style="display: flex; flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][14]')"
                            value="媒體宣傳平台，平台名:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers3"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                    <div style="display: flex; flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('IncubatorApply.TeamData.checkbox[0][15]')"
                            value="其他:"
                            color="#898989"
                            class="checkbox"
                            readonly
                        />
                        <v-text-field
                            v-model="refers4"
                            class="checkbox-input border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <!-- 提案 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[6]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            :label="$t('IncubatorApply.Confirm.label[11]')"
                            placeholder=""
                            :hint="$t('IncubatorApply.Confirm.hint[2]')"
                            persistent-hint
                            class="border-orange-input form-input"
                            readonly
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[7]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.property"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="臺灣團隊-孵化組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[3]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[0]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[1]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[2]') }}
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="臺灣團隊-加速組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[4]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[3]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[4]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[5]') }}
                                </li>
                            </ol>
                        </div>
                        <v-radio
                            value="亞太地區團隊"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[5]') }}
                                </div>
                            </template>
                        </v-radio>
                        <div class="radio-content">
                            <ol>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[6]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[7]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[8]') }}
                                </li>
                                <li>
                                    {{ $t('IncubatorApply.Confirm.content[9]') }}
                                </li>
                            </ol>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[8]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="500"
                        :label="$t('IncubatorApply.Confirm.label[12]')"
                        no-resize
                        outlined
                        placeholder=""
                        :hint="$t('IncubatorApply.Confirm.hint[3]')"
                        persistent-hint
                        readonly
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[9]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.issue"
                        class="radio-group"
                        readonly
                    >
                        <v-radio
                            value="慈善創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[6]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="醫療照護"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[7]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="教育創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[8]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="環境保護"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[9]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="循環經濟"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[10]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="青銀共創"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[11]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="食糧供應創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[12]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="災害防救創新"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[13]') }}
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="地方創生/社區營造"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('IncubatorApply.Confirm.radio[14]') }}
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <!-- 團隊照片 -->
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[10]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-img
                            height="300px"
                            :src="$static(form.proposal.img)"
                            class="team-image"
                        />
                    </v-card>
                </div>
            </div>
            <!-- 計畫書 -->
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorApply.Confirm.title[11]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="pdf-file">
                        <v-icon class="icon">
                            mdi-paperclip
                        </v-icon>
                        <a
                            :href="$static(form.proposal.pdfFile)"
                            target="_blank"
                            class="preview-link"
                        >{{ $t('IncubatorApply.Confirm.link') }}</a>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="apply-form-block">
            <div class="left-block" />
            <div class="form-content">
                <div class="actions">
                    <div
                        class="btn white-btn"
                        @click="prevStep"
                    >
                        {{ $t('IncubatorApply.Confirm.button[0]') }}
                    </div>
                    <div
                        class="btn orange-btn"
                        @click="submit"
                    >
                        {{ $t('IncubatorApply.Confirm.button[1]') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            countries: ['臺灣', '其他'],
            cities: [
                '臺北市',
                '新北市',
                '基隆市',
                '桃園市',
                '新竹市',
                '新竹縣',
                '苗栗縣',
                '臺中市',
                '彰化縣',
                '南投縣',
                '雲林縣',
                '嘉義市',
                '嘉義縣',
                '臺南市',
                '高雄市',
                '屏東縣',
                '宜蘭縣',
                '花蓮縣',
                '臺東縣',
                '澎湖縣',
                '金門縣',
                '連江縣',
            ],
            refers1: '',
            refers2: '',
            refers3: '',
            refers4: '',
        };
    },
    computed: {
        ...mapState('mIncubatorApply', [
            'form',
            'refersTeamName',
            'refersRecommendName',
            'refersPlatformName',
            'refersOther',
        ]),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        ...mapActions('mIncubatorApply', ['loadApply']),
        async init() {
            if (this.applyId !== 'new') {
                await this.loadApply(this.applyId);
            }
            this.refers1 = this.refersTeamName;
            this.refers2 = this.refersRecommendName;
            this.refers3 = this.refersPlatformName;
            this.refers4 = this.refersOther;
        },
        memberNameLabel(index) {
            if (index === 0) {
                return this.$t('IncubatorApply.Confirm.label[5]');
            }
            return `${this.$t('IncubatorApply.Confirm.label[6]')}${index}`;
        },
        prevStep() {
            this.$router.push({
                name: 'IncubatorProposal',
                params: {
                    id: this.applyId,
                },
            });
        },
        submit() {
            this.setLoading(true);
            API.IncubatorApplyUtil.Submit(this.applyId)
                .then(() => {
                    this.setLoading(false);
                    this.$router.push({
                        name: 'IncubatorComplete',
                        id: this.applyId,
                    });
                })
                .catch((e) => {
                    this.setLoading(false);
                    this.handleError({
                        message: e || this.$t('alertMessage.register-fail'),
                        color: 'error',
                    });
                });
        },
    },
};
</script>

<style lang="scss">
.incubator-confirm {
    .radio-content {
        padding-left: 34px;
        margin-bottom: 10px;
    }

    .member-file {
        flex: 1;
        display: flex;
        align-items: center;
        margin-top: -50px;
    }

    .team-image-block {
        .preview-card {
            width: 300px;
            height: 300px;
        }
    }

    .pdf-file {
        a {
            color: $color-orange;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-left: 5px;
        }
    }

    .actions {
        justify-content: center;

        .btn:first-child {
            margin-right: 30px;
        }
    }
}
.checkbox-input {
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
@media (min-width: 768px) {
    .checkbox-input {
        max-width: 185px !important;
    }
}
</style>
